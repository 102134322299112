<template>
  <b-form @submit.prevent="storeDevis()">
    <b-row>
      <b-col md="6">
        <b-card title="Affreteur">
          <b-row>
            <b-col
              class="py-0 my-0"
              md="12"
            >
              <b-form-group
                class="p-0 m-0 text-bold"
                label="Nom"
                label-for="hi-first-name"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  {{ offre.affreteur.firstname }} {{ offre.affreteur.lastname }}
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              class="py-0 my-0"
              md="12"
            >
              <b-form-group
                class="p-0 m-0"
                label="Email"
                label-for="hi-email"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  {{ offre.affreteur.email }}
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              class="py-0 my-0"
              md="12"
            >
              <b-form-group
                label="Téléphone"
                class="p-0 m-0"
                label-for="hi-number"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  {{ offre.affreteur.telephone }}
                </b-input-group>
              </b-form-group>
            </b-col>

          </b-row>
        </b-card>

      </b-col>
      <b-col md="6">
        <b-card title="Moyen de transport">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Catégorie"
                class="pt-0 mt-0"
                label-for="hi-first-name"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge ">
                  CAMION CITERNE
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Type"
                label-for="hi-email"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  CITERNE POUR PULVERULENTS
                </b-input-group>
              </b-form-group>
            </b-col>

          </b-row>

        </b-card>

      </b-col>

    </b-row>
    <b-row>
      <b-col md="6">
        <b-card title="Itinéraire">
          <b-row>
            <b-col
              class="py-0 my-0"
              md="12"
            >
              <b-form-group
                class="p-0 m-0 text-bold"
                label="Départ"
                label-for="hi-first-name"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  <!-- KPASSE ( OUIDAH ) -->
                  {{ offre.ville_depart.name }}
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              class="py-0 my-0"
              md="12"
            >
              <b-form-group
                class="p-0 m-0"
                label="Dte départ"
                label-for="hi-email"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  {{ offre.date_prev_depart }}
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              class="py-0 my-0"
              md="12"
            >
              <b-form-group
                label="Destination"
                class="p-0 m-0"
                label-for="hi-number"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  <!-- FIFADJI ( COTONOU ) -->
                  {{ offre.ville_destination.name }}
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              class="py-0 my-0"
              md="12"
            >
              <b-form-group
                label="Dte arrivée"
                class="p-0 m-0"
                label-for="hi-number"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  {{ offre.date_prev_livraison }}
                </b-input-group>
              </b-form-group>
            </b-col>

          </b-row>
        </b-card>

      </b-col>
      <b-col md="6">
        <b-card title="Marchandise">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Catégorie"
                class="pt-0 mt-0"
                label-for="hi-first-name"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge ">
                  {{ offre.categorie_marchandise.libelle }}

                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Type"
                label-for="hi-email"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  {{ offre.marchandise.name }}
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Poids"
                label-for="hi-email"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  {{ offre.poids_marchandise }} ({{ offre.unite_mesure.libelle }})
                </b-input-group>
              </b-form-group>
            </b-col>

          </b-row>

        </b-card>

      </b-col>

    </b-row>

    <b-card title="Ajouter un devis">
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="camion"
            rules="required"
          >
            <b-form-group
              label="Choix du camion"
              label-for="moyens_transport"
            >
              <v-select
                id="moyens_transport"
                v-model="form.moyen_transport_id"
                label="libelle"
                placeholder="Choix du camion"
                :state="errors.length > 0 ? false : null"
                :options="moyensTransport"
                :reduce="(moyensTransport) => moyensTransport.id"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Proposer la date de chargement"
            label-for="date_prev_depart"
          >
            <flat-pickr
              v-model="form.date_prev_depart"
              class="form-control"
              placeholder="Proposer la date de chargement"
              :config="config"
            />
          </b-form-group>
        </b-col>
        <b-col md="6 mt-1">
          <b-form-group
            label="Proposer la date de livraison"
            label-for="date_prev_livraison"
          >
            <flat-pickr
              v-model="form.date_prev_livraison"
              class="form-control"
              placeholder="Proposer la date de livraison"
              :config="config"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          class="my-1"
        >
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Devise"
                label-for="libelle"
              >
                <v-select
                  v-model="form.devise_id"
                  label="libelle"
                  :options="devises"
                  placeholder="Devise"
                  :reduce="(devises) => devises.id"
                />
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group
                label="Prix"
                label-for="montant"
              >
                <cleave
                  id="montant"
                  v-model="form.montant"
                  class="form-control"
                  :raw="false"
                  :options="options.number"
                  placeholder="10 000"
                />
              </b-form-group>
            </b-col>
          </b-row>

        </b-col>

        <!-- submit and reset -->
        <b-col md="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class=" float-right"
            :disabled="offreSuccess"
          >
            Enregistrer
            <b-spinner
              v-if="offreSuccess"
              small
              label="Loading..."
              variant="light"
              class="ml-1"
            />
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="secondary"
            class="mr-1 float-right"
          >
            Rafraichir
          </b-button>
        </b-col>

      </b-row>

    </b-card>

  </b-form>

</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { onMounted, reactive } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'

import {
  BFormGroup, BCard, BSpinner, BForm, BButton, BInputGroup, BRow, BCol,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import useSocieteTransporteurs from '@/services/societe/societeTransporteurService'
// eslint-disable-next-line import/no-cycle
import useDevises from '@/services/admin/deviseservice'

// eslint-disable-next-line import/no-extraneous-dependencies, import/extensions
import { French } from 'flatpickr/dist/l10n/fr.js'
// eslint-disable-next-line import/no-cycle
import useInvoices from '@/services/invoice/invoiceService'

export default {
  components: {
    BCard,
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    Cleave,
    BInputGroup,
    BSpinner,
    BForm,
    vSelect,
    BButton,
    flatPickr,

  },
  directives: {
    Ripple,

  },
  setup() {
    const {
      createDevis, getOfferByCode, offre, offreSuccess, oneDevis, getTransporteurs, transporteurInternes, getTransporteursMeans, moyensTransport,
    } = useSocieteTransporteurs()
    const {
      devises, getDevises,
    } = useDevises()
    const {
      createOrUpdateInvoice,
    } = useInvoices()
    const form = reactive({
      moyen_transport_id: '',
      date_prev_depart: '',
      date_prev_livraison: '',
      devise_id: '',
      annee_experience: 6,
      montant: '',
      offre_id: null,
      code: null,
      code_offre: null,
      societe_transport_id: null,
      affreteur_id: null,
    })
    const invoiceData = reactive({
      devis_id: null,
      offre_id: null,
      affreteur_id: null,
      user_id: null,
    })
    const options = reactive({

      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalMark: ',',
        delimiter: ' ',
      },

    })
    onMounted(async () => {
      await getOfferByCode()
      await getDevises()
      await getTransporteurs()
      await getTransporteursMeans()
      form.date_prev_depart = offre.value.date_prev_depart
      form.date_prev_livraison = offre.value.date_prev_livraison
      console.clear()
    })

    const selected = [{ title: 'Chox du camion' }]
    const option = [{ title: 'Chox du camion' }, { title: 'RB 4526 (Chassis: 12345678)' }, { title: 'RB 4567 (Chassis: 12345678)' }, { title: 'RB 4896 (Chassis: 12345678)' }]
    const date = null
    const dateDefault = null
    const timePicker = null
    const dateNtim = null
    const multiDate = null
    const rangeDate = null
    const humanDate = null
    const disableDate = null
    const inlineDate = null
    const means = moyensTransport.type_camion

    // Reset Form
    const resetForm = () => {
      form.moyen_transport_id = null
      form.transporteur_interne_id = null
      form.date_prev_depart = null
      form.date_prev_livraison = null
      form.montant = null
    }

    const storeDevis = async () => {
      form.offre_id = offre.value.id
      form.affreteur_id = offre.value.affreteur_id
      form.code_offre = offre.value.code
      await createDevis({ ...form })
      invoiceData.devis_id = oneDevis.value.id
      invoiceData.offre_id = oneDevis.value.offre_id
      invoiceData.affreteur_id = oneDevis.value.affreteur_id
      invoiceData.user_id = oneDevis.value.transporteur === null ? oneDevis.value.societe_transport_id : oneDevis.value.transporteur_independant_id
      await createOrUpdateInvoice({ ...invoiceData })
    }

    const config = {
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: 'j M Y H i',
      altInput: true,
      enableTime: true,
      dateFormat: 'Y-m-d H:i',
      locale: French, // locale for this instance only
    }
    return {
      config,
      date,
      dateDefault,
      timePicker,
      dateNtim,
      multiDate,
      rangeDate,
      humanDate,
      disableDate,
      offreSuccess,
      inlineDate,
      selected,
      option,
      form,
      options,
      resetForm,
      storeDevis,
      offre,
      devises,
      transporteurInternes,
      moyensTransport,
      means,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
@/services/admin/deviseService